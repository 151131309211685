.home-page {
	// $page_padding-vertical * 2
	--section-vertical-padding: 4rem;
	// Set in general-layout under .content-body section

	section {
		min-height: calc(100dvh - var(--approx-menu-height));
		&.image-left,
		&.image-right {
			.image {
				max-height: calc(100dvh - var(--approx-menu-height));
			}
		}
	}

	// ------------------------------------------------------------------
	// Landing Section
	// ------------------------------------------------------------------

	#landing-section {
		position: relative;
		min-height: 100dvh;
		padding: $page_padding-vertical $page_padding-horizontal;

		// Positioning the top section to sit above the normal menu
		z-index: 120;
		margin-top: calc(($page_padding-vertical + 3rem) * -1);

		.logo {
			display: grid;
			grid-template-rows: auto auto;
			grid-template-columns: 250px 1fr;
			grid-template-areas:
				'logo title'
				'empty subtitle';

			column-gap: $spacing-lg;
			row-gap: $spacing-xxs;
			align-items: center;

			img {
				grid-area: logo;
				align-self: center;
				justify-self: stretch;

				width: 100%;
				height: auto;
			}
			/* 
			h1 {
				grid-area: title;

				margin: 0;
				font-size: 2.5rem;
				line-height: 1.1em;
			}

			sub {
				grid-area: subtitle;
				font-size: 0.8rem;
				font-family: $font-display-normal-family;
			} */
		}

		// ---- Menu ----
		nav {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;

			a {
				line-height: 1.5em;
				vertical-align: middle;
				font-weight: 400;

				svg {
					vertical-align: bottom;
					margin-left: 0.5rem;
				}
			}

			div.spacer {
				flex-grow: 1;
			}
		}

		// ---- Responsiveness ----

		// Desktop size
		@include responsive($page-break_tablet, min) {
			display: grid;
			grid-template-columns: 20rem 1fr;
			.logo {
				align-self: flex-end;
			}
			nav {
				align-self: stretch;
				text-align: right;
				align-items: flex-end;
				gap: $spacing-xxl;
			}
		}
		// Breaking background as required
		&.icon-bg {
			@include responsive($page-break_desktop, max) {
				background-size: 100%;
			}
			@include responsive(33rem) {
				background-size: auto 60%;
			}
		}

		// Tablet / large phone width
		@include responsive($page-break_tablet, max) {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: $page_padding-vertical * 1.5;
			.logo {
				margin-top: $page_padding-vertical * 1.5;
			}
			nav {
				align-items: center;
				text-align: center;
				font-size: 1.25rem;
				gap: $page_padding-vertical * 0.5;
				.spacer {
					display: none;
				}
				a {
					padding: 0.5em 1em;
					display: block;
				}
				.education-hub-login {
					order: 10;
				}
			}
		}

		// When the screen is too short
		@include responsive(38rem, max, height) {
			.logo {
				margin: 0;
				grid-template-columns: 50px 1fr;
				grid-template-areas:
					'logo title'
					'subtitle subtitle';
				row-gap: 1rem;
				sub {
					text-align: center;
					font-size: 0.75rem;
				}
				h1 {
					font-size: 1.5rem;
				}
			}
			nav {
				font-size: 1rem;
			}
		}
	}

	// ------------------------------------------------------------------
	// Other Sections
	// ------------------------------------------------------------------

	#landing-section + section {
		padding-top: calc(var(--approx-menu-height) + var(--section-vertical-padding));
		min-height: 100dvh;
		&.image-left,
		&.image-right {
			.image {
				max-height: calc(100dvh - var(--approx-menu-height) - var(--section-vertical-padding));
			}
		}

		@include responsive($page-break_desktop) {
			&.image-left,
			&.image-right {
				.image {
					max-height: calc(100dvh - var(--section-vertical-padding));
				}
			}
		}

		@include responsive($page-break_tablet) {
			padding-top: var(--section-vertical-padding);
			min-height: unset;
			border-top: 2px solid $color-white;
		}
	}

	.about-section {
		$image-width: 30dvw;
		--content-padding: 8dvw;
		min-height: 100dvh;
		display: grid;
		column-gap: 10dvw;
		position: relative;

		h2 {
			border-top: none;
			padding-top: 0;
			margin-top: 0;
		}

		// Setting up the layouts
		&.image-right {
			grid-template-columns: 1fr $image-width;
			grid-template-areas: 'content image';
			padding-right: 0;
			padding-left: var(--content-padding);
			.image {
				mask-position: left center;
				overflow: hidden;
				.position-object {
					aspect-ratio: 1/1;
					object-fit: cover;
					object-position: 80% center;
				}
			}
		}
		&.image-left {
			grid-template-columns: $image-width 1fr;
			grid-template-areas: 'image content';
			padding-left: 0;
			padding-right: var(--content-padding);
			.image {
				mask-position: right center;
			}
		}

		.image {
			grid-area: image;
			align-self: stretch;
			position: relative;
			overflow: hidden;
			mask-image: url(/images/ndc_icon-inverted.svg);
			mask-repeat: no-repeat;
			mask-size: cover;
			img {
				width: auto;
				height: 100%;
			}
		}

		.content {
			grid-area: content;
			align-self: center;

			p {
				max-width: 800px;
			}
		}

		@include responsive($page-break_desktop) {
			display: block;
			overflow-x: hidden;
			--content-padding: 5rem;
			&.image-left,
			&.image-right {
				padding-left: var(--content-padding);
				padding-right: var(--content-padding);
			}
			.image {
				position: absolute;
				height: 65%;
				right: -20%;
				top: 15%;
				opacity: 10%;
			}
			.content {
				z-index: 2;
				position: relative;
				h2 {
					margin-bottom: 1.5em;
				}
			}

			&#about-3 {
				min-height: unset;
				.image {
					right: -10vw;
				}
			}
		}

		@include responsive($page-break_tablet) {
			--content-padding: 3rem;
			min-height: unset;
			border-top: 2px solid $color-white;
			h2 {
				margin-top: 0;
			}

			.image {
				display: none;
			}
		}
	}

	.testimonial-section {
		@include responsive($breakpoint-tablet) {
		}

		.content {
			grid-area: content;
			align-self: center;
			min-width: 700px;
			@include responsive(85rem) {
				min-width: 500px;
			}
			@include responsive(42rem) {
				min-width: 0;
			}
		}

		.testimonial-container {
			position: relative;
		}

		.testimonial-container .carousel-btn {
			color: $color-primary;
		}

		@include responsive(42rem) {
			.testimonial-container {
				display: block;
				.carousel-btn {
					position: initial;
					margin: 0 $page-spacing * 0.5;
				}
			}
		}

		.testimonial-carousel {
			display: flex;
			overflow: hidden;
			transition: transform 0.5s ease;
			will-change: transform;
		}

		.testimonial-item {
			min-width: 100%;
			box-sizing: border-box;
			padding: 30px;
		}
	}

	#about-4 {
		min-height: unset;
		&.image-right {
			.image .position-object {
				object-position: 90%;
				transform: scale(1.3);
			}
		}
	}
}
