@mixin inverted-logo-bg {
	background-image: url(/images/icon_bg_coloured-reflected.png);
	background-repeat: no-repeat;
	background-position: calc(100% + 40px) calc(100% + 40px);
	background-size: 600px;
	grid-template-columns: 1fr var(--image-width);
}

// ------------------------------------------------------------------
// About Page
// ------------------------------------------------------------------

.about-page {
	--image-width: 350px;

	h2 {
		padding-top: 0;
		border-top: none;
	}

	#about-dr-pam,
	#about-logo {
		display: grid;
		grid-column-gap: $page_padding-horizontal * 3;

		align-items: center;
	}

	#about-dr-pam {
		grid-template-columns: var(--image-width) 1fr;
		.image-container {
			img {
				mask-image: url(/images/circle-shape.png);
				mask-repeat: no-repeat;
				mask-position: center;
				mask-size: contain;
				width: auto;
				height: calc(100% - 12px);
			}

			background-image: url(/images/circle-shape.png);
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			width: var(--image-width);
			height: var(--image-width);

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	#about-logo {
		@include inverted-logo-bg;

		img {
			width: 100%;
			height: auto;
		}
	}

	@include responsive($page-break_desktop) {
		--image-width: 200px;
		#about-dr-pam,
		#about-logo {
			display: flex;
			flex-direction: column;
			row-gap: $page_padding-vertical * 1;
			.image-container {
				order: -1;
				align-self: flex-start;
			}
		}
		#about-logo {
			.image-container {
				width: var(--image-width);
			}
		}
	}
}

// ------------------------------------------------------------------
// Courses Page
// ------------------------------------------------------------------

.courses-list {
	// Also used on the home page

	padding: 0;
	margin: $page_padding-vertical * 1 0 0;

	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: flex-start;

	a {
		line-height: 1.5em;
		font-weight: 600;
		color: inherit;
		font-size: 1rem;
		&:after {
			border-width: 2px;
		}
		span {
			display: block;
			font-size: 0.8em;
			margin-top: 0.15em;
			font-weight: 300;
		}
	}
	li {
		display: flex;
		flex-direction: column;
		list-style: none;
		cursor: pointer;
		.classes {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			a {
				flex-grow: 1;
			}
			.separator {
				font-family: $font-display-normal-family;
				margin-left: 10px;
				margin-right: 10px;
			}
		}
		.no-after::after {
			display: none;
			cursor: default;
		}
	}
}

.email-link {
	a {
		color: $color-accent-1;
	}
}

.acc-logos-container {
	// Also used on the home page, and specific course details pages
	width: 100%;
	display: flex;
	align-items: center;
	flex-grow: 1;
	flex-shrink: 1;
	gap: $page_padding-horizontal * 1.5;
	margin-bottom: 30px;

	@include responsive($page-break_tablet) {
		flex-wrap: wrap;
		justify-content: center;
	}

	img {
		width: auto;
		height: 100px;
	}

	&.with-border {
		gap: $page_padding-horizontal;
		padding-top: $page_padding-vertical;
		margin-top: $page_padding-vertical;
		border-top: 2px solid $color-primary;
	}

	&.with-border-small {
		gap: $page_padding-horizontal;
		border-top: 2px solid $color-primary;
		margin-top: 20px;
		padding-top: 10px;
		margin-bottom: 100px;
	}

	&.small-logos {
		&.with-border {
			margin-top: $page_padding-vertical * 0.75;
			padding-top: $page_padding-vertical * 0.75;
		}
		img {
			height: 50px;
		}
	}
}

// Calendar, used in masterclasses pages

@mixin button-calendar-styles($bg-color, $text-color) {
	background-color: $bg-color !important;
	color: $text-color;
	border-color: transparent !important;
	border-radius: 0 !important;
	box-shadow: none !important;
}

@mixin text-calendar-styles($color, $weight: normal, $size: $font-size-sm) {
	color: $color;
	font-weight: $weight;
	font-size: $size;
}

@mixin event-calendar-content {
	display: block;
	line-height: 1.2em;
	white-space: nowrap;
}

.calendar-container {
	.fc-toolbar {
		background-color: $color-primary;
		color: $color-white;
		font-size: $font-size-sm;
		padding: 0 10px;

		.fc-toolbar-title {
			padding-bottom: 30px;
			font-size: $font-size-lg;
		}
	}

	.fc .fc-daygrid-day {
		border: 1px solid $color-rose-quartz;
		overflow: hidden;
		position: relative;
	}

	.fc-day-today {
		background-color: $color-thistle !important;
	}

	.fc-col-header {
		background-color: $color-primary;
		color: $color-white;

		.fc-col-header-cell-cushion {
			color: $color-white;
			font-weight: $font-body-bold-weight;
			padding: 5px;
		}
	}

	.fc-daygrid-day-number {
		@include text-calendar-styles(black, $font-body-bold-weight);
		pointer-events: none;
	}

	.fc .fc-scrollgrid,
	.fc .fc-scrollgrid-section,
	.fc .fc-scrollgrid-sync-table {
		border: 1px solid $color-rose-quartz;
	}

	.fc-event {
		@include text-calendar-styles(black);
		background-color: $color-snow;

		&:hover {
			background-color: $color-snow;
		}

		.fc-event-content {
			@include event-calendar-content;
		}

		.fc-event-time-container {
			display: flex;
			align-items: center;
		}

		svg {
			color: $color-primary;
		}

		.fc-event-time,
		.fc-event-title {
			display: inline-block;
			font-weight: normal;
			white-space: normal;
		}
	}

	.fc-event-past {
		opacity: 0.3;
	}

	.fc-col-header-cell-cushion {
		@include text-calendar-styles($color-primary, $font-body-bold-weight);
	}

	.fc-button,
	.fc-today-button {
		@include button-calendar-styles($color-primary, $color-white);
	}

	.fc-button.fc-button-active {
		@include button-calendar-styles($color-rose-quartz, $color-white);
	}

	.fc-button-primary:not(:disabled):hover {
		background-color: $color-rose-quartz !important;
		border-color: $color-rose-quartz !important;
	}

	@include responsive($page-break_tablet) {
		.fc-toolbar {
			flex-direction: column;
			align-items: stretch;

			.fc-toolbar-title {
				padding: 5px 0;
				order: 2;
			}

			.fc-button-group {
				order: 1;
				justify-content: center;
			}

			.fc-button {
				font-size: $font-size-xs;
			}
		}
	}
}

// ------------------------------------------------------------------
// FAQs Page
// ------------------------------------------------------------------

.faqs-page {
	.faqs-category-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	.faq-category {
		margin-bottom: 2em;
		> a {
			color: $color-primary;
			word-break: normal;

			@include active-state {
				cursor: pointer;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	.faq-category-questions {
		list-style-type: none;
		border-top: 2px solid $color-primary;
		border-bottom: 2px solid $color-primary;
		padding: 3em 1.5em;
		margin-top: 1.5em;
	}
	.question {
		margin-bottom: 1.5em;
		> a {
			color: $color-primary;
			word-break: normal;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	.faq-answer {
		background: get-transparent-color($color-primary, 10%);
		padding: 1em 1.5em;
		margin-top: 1.5em;

		// Hide "hero" images from FAQ
		> img:first-child,
		> p:first-child > img:first-child {
			display: none;
		}

		p {
			margin-bottom: 1em;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@include responsive($page-break_desktop) {
		.faq-category-questions {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

// ------------------------------------------------------------------
// Course Details Pages
// ------------------------------------------------------------------

.course-details-page {
	position: relative;

	h1 {
		margin-top: 0;
	}
	.course-tag {
		margin-bottom: 0.5em;
		font-family: $font-display-bold-family;
		font-weight: $font-display-bold-weight;
	}

	h2 {
		margin-top: $page_padding-vertical * 2;
	}
	section > *:first-child {
		margin-top: 0;
	}

	h3 {
		margin-bottom: 0.5em;
		font-weight: 600;
		font-size: 1.5rem;
		margin-top: 2.25em;
	}

	.underline {
		border-bottom: 1.5px solid $color-primary;
	}

	.acc-logos-container {
		width: 100%;
		display: flex;
		align-items: center;
		flex-grow: 1;
		flex-shrink: 1;
		gap: $page_padding-horizontal;
		img {
			width: auto;
			height: 100px;
		}
		&.small-logos {
			&.with-border {
				margin-top: $page_padding-vertical * 0.75;
				padding-top: $page_padding-vertical * 0.75;
			}
			img {
				height: 50px;
			}
		}
	}

	table {
		thead p {
			font-weight: 600;
		}
		p,
		ol,
		li {
			font-size: 0.85rem;
			margin-bottom: 0.5em;
			line-height: 1.5em;
		}

		td {
			border-bottom: 2px solid $color-primary;
		}

		td {
			padding: 1em 0.5em;
		}
	}

	.buy-course {
		position: fixed;
		bottom: $page_padding-vertical;
		right: $page_padding-horizontal;
		z-index: 140;
		font-family: $font-body-normal-family;
		padding: 1em 3em;
		border: 1px solid $color-white;
		@include responsive($page-break_tablet) {
			bottom: 0;
			width: 100%;
			right: 0;
			padding: 2em 1em;
		}
	}

	#buy-lactation-modules {
		ul {
			padding: 2rem 0;
			margin: 0;

			li {
				list-style-type: none;
				margin-bottom: 1.5rem;
				&:last-child {
					margin-bottom: 0;
				}
				.programme-info {
					display: flex;
					justify-content: space-between;
					align-items: center;

					> p {
						font-weight: 600;
						margin: 0;
						margin-right: 0.5rem;
					}
				}
			}
		}
	}
	section#ndc-lactation-section {
		padding-bottom: 0;
	}
}

// ------------------------------------------------------------------
// Resources Page
// ------------------------------------------------------------------
.resources-page {
	position: relative;

	.free-resource-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.resource-categories {
		margin-bottom: 2em;
		> a {
			color: $color-primary;
			word-break: normal;
			cursor: pointer;

			@include active-state {
				cursor: pointer;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	.resource-subcategories {
		list-style-type: none;
		border-top: 2px solid $color-primary;
		border-bottom: 2px solid $color-primary;
		padding: 3em 1.5em;
		margin-top: 1.5em;

		@include active-state {
			cursor: pointer;
		}
	}

	.title {
		list-style-type: none;
		margin-bottom: 1em;
		padding: 0;
		> a {
			color: $color-primary;
			word-break: normal;
			cursor: pointer;
		}
		@include active-state {
			cursor: pointer;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.results-list {
			background-color: $color-snow;
			padding: 10px 20px;
			margin-bottom: 10px;
			display: flex;
			align-items: center;

			> a {
				color: $color-primary;
				word-break: normal;
			}
			.no-after::after {
				content: none;
			}

			&:hover {
				background-color: $color-thistle;
			}

			svg {
				margin-right: 10px;
				flex-shrink: 0;
			}
		}
	}
}

// ------------------------------------------------------------------
// Resources/id/slug Page
// ------------------------------------------------------------------

.articleContainer {
	h1 {
		font-size: 2rem;
	}
	.markdown {
		a {
			word-break: normal;
		}
		iframe {
			@include responsive($page-break_tablet) {
				height: 220px;
			}
		}
		iframe + p {
			padding-top: 20px;
		}
	}
}

.back-to-resources-list {
	position: fixed;
	bottom: $page_padding-vertical;
	right: $page_padding-horizontal;
	z-index: 140;
	font-family: $font-body-normal-family;
	padding: 1em 3em;
	border: 1px solid $color-white;
	@include responsive($page-break_tablet) {
		bottom: 0;
		width: 100%;
		right: 0;
		padding: 2em 1em;
	}
}

// ------------------------------------------------------------------
// Purchase page
// ------------------------------------------------------------------

.purchase-page {
	.products {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 3rem;
	}

	.product {
		padding: 1.25em;
		background: $color-brand-2;
		min-width: 22rem;
		max-width: 35dvw;
		flex-grow: 1;
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		gap: $spacing-sm;

		@include responsive($page-break_desktop) {
			// max-width: 45dvw;
			min-width: 100%;
		}

		@include responsive($page-break_tablet) {
			max-width: 100%;
			min-width: 100%;
		}

		.description {
			white-space: pre-wrap;
		}

		p,
		.programmes {
			font-size: 0.9em;
		}
		button {
			margin-top: auto;
		}
		.price + button {
			margin-top: 0;
		}
		p {
			margin-bottom: 0;
		}

		.selected {
			font-weight: bold;
		}

		.purchased {
			font-style: italic;
			&:after {
				content: ' (already purchased)';
				font-size: $font-size-sm;
			}
		}

		.programmes {
			margin: 1.5em 0 1.5em;
			padding-left: 1.5em;
		}
		.price {
			text-align: center;
			margin: auto 0 0.5rem 0;
			font-weight: 500;
			.currency {
				padding-left: 0.25em;
			}
		}
	}
}

// ------------------------------------------------------------------
// Signup page
// ------------------------------------------------------------------
.signup-page {
	p.footnote {
		text-align: right;
		font-size: $font-size-sm;
	}

	.address {
		margin: $spacing-sm 0;
	}

	.StripePaymentForm {
		margin-bottom: $spacing-lg;
	}

	.powered-by-stripe {
		margin: 0 auto 0 0;
		display: flex;
		gap: $spacing-sm;
		align-items: center;

		svg {
			fill: $color-primary;
			width: 128px;
		}

		span {
			font-size: $font-size-sm;
		}
	}
}

// ------------------------------------------------------------------
// signup complete page
// ------------------------------------------------------------------
.signup-complete-page {
	.ProductPurchased {
		a {
			margin: $spacing-lg auto 0 auto;
			width: max-content;
			display: block;
		}
	}
}

// ------------------------------------------------------------------
// Find an NDC Practitioner page
// ------------------------------------------------------------------
.find-an-ndc-practitioner-page {
	--results-width: 25rem;
	--results-gap: 3rem;
	--results-height: 70dvh;
	.function-container {
		margin-top: $page_padding-vertical * 1.5;
		display: flex;
		flex-wrap: wrap;

		.fineprint {
			margin-top: $page_padding-vertical;
			p {
				font-size: 0.75em;
				font-style: italic;
			}
		}
	}

	.search-wrapper {
		flex-basis: 100%;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		column-gap: $page_padding-horizontal;
		row-gap: $page_padding-vertical * 0.25;

		margin-bottom: $page_padding-vertical * 1.5;

		input {
			margin-bottom: 0;
		}

		.divider {
			border-left: 2px solid;
			align-self: stretch;
		}

		.filter-wrapper {
			.search-radius-wrapper {
				display: flex;
				align-items: center;
				gap: $spacing-sm;
				position: relative;

				span {
					font-size: 0.9em;
				}

				&:after {
					content: 'km';
					position: absolute;
					right: 0.3em;
					top: 50%;
					transform: translateY(calc(-50%)); // -2px to match the input's bottom border
					font-family: $font-body-normal-family;
					font-weight: $font-body-normal-weight;
					font-size: 0.9rem; // To match the input
					opacity: 0.75;
				}

				.search-radius {
					width: 6em;
					padding-right: 3em; // allow room for the above :after
					-moz-appearance: textfield;
					text-align: right;
					border: none;

					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
				}
			}
		}
	}

	.map-container {
		width: 100%;
		flex-basis: calc(100% - var(--results-width) - var(--results-gap));
		height: var(--results-height);
		flex-grow: 1;
	}

	.results-list {
		&:not(:empty) {
			height: var(--results-height);
			flex-basis: var(--results-width);
			margin-right: var(--results-gap);
		}
		overflow-y: auto;

		.Practitioner {
			margin: $spacing-sm 0;
			padding: $spacing-sm;
			box-sizing: border-box;
			border: 1px solid transparent;

			&:hover {
				background: $color-brand-2;
				cursor: pointer;
			}

			&.selected {
				border: 2px solid $color-primary;
			}
		}
	}

	.Practitioner {
		p {
			margin: 0;
			font-size: $font-size-md;
		}

		a {
			text-decoration: none;
			&::after {
				display: none;
			}

			&:hover {
				text-decoration: underline;
			}
		}

		.name {
			font-weight: $font-body-bold-weight;
		}

		.telehealth {
			font-size: $font-size-sm;
			display: grid;
			grid-template-columns: auto 1fr;
			align-items: center;

			svg {
				margin-right: 0.5em;
			}
		}

		.address {
			white-space: pre;
			font-size: $font-size-sm;
			line-height: 1.6;

			& + .phone,
			& + .email,
			& + .website {
				margin-top: $spacing-xs;
			}
		}

		.phone,
		.email,
		.website {
			font-size: $font-size-sm;
			line-height: 1.6;
		}
	}

	@include responsive($page-break_desktop) {
		.function-container {
			display: block;
		}
		.search-wrapper {
			flex-wrap: wrap;
			margin-bottom: $page_padding-vertical;
			.divider {
				display: none;
			}
			.filter-wrapper {
				flex-basis: 100%;
				.search-radius-wrapper {
					justify-content: flex-end;
				}
			}
		}
		.results-list {
			max-height: 80dvh;

			&:not(:empty) {
				height: auto;
				margin-right: unset;
				border-bottom: 2px solid;
				margin-bottom: $page_padding-vertical * 1.5;
			}

			&.has-results {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: $page_padding-horizontal;
				grid-template-rows: auto;

				> p {
					grid-column: 1 / span 2;
					border-bottom: 2px solid;
				}
			}

			> p {
				padding-bottom: $page_padding-vertical * 0.5;
				margin-bottom: $page_padding-vertical * 0.5;
				max-height: 80dvh;
			}
		}
	}

	@include responsive($page-break_tablet) {
		.results-list {
			grid-template-columns: 1fr;
			max-height: 70dvh;
			&:not(:empty) {
				margin-bottom: $page_padding-vertical;
			}
			> p {
				grid-column: unset;
			}
		}
	}
}
