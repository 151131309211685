#footer-container {
	padding: 0;
}

.endcap {
	text-align: center;
	padding: $page_padding-vertical * 0.5 $page_padding-horizontal;
	img {
		width: auto;
		height: auto;
		max-height: 50px;
		margin: 0 $page_padding-horizontal * 0.5;
	}
}

footer {
	--logo-size: 70px;

	border-top: 2px solid $color-white;

	padding: $page_padding-vertical $page_padding-horizontal * 1.5;

	display: flex;
	flex-wrap: nowrap;
	gap: $page_padding-horizontal * 4;

	p,
	a {
		font-size: 0.85rem;
	}

	// Spreading the vertical alignment of each column
	.ndc-logo,
	.drpam-logo {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		flex-direction: column;
	}

	// Left column
	.ndc-logo {
		align-items: flex-start;
		img {
			height: var(--logo-size);
			width: auto;
		}

		p {
			font-family: $font-display-bold-family;
			font-weight: $font-display-bold-weight;
			letter-spacing: $font-body-bold-spacing;
			font-size: 1.55rem;
			margin-bottom: 0;
			margin-top: 1.5em;
			line-height: 1.15em;
		}
	}

	// Central column
	.links {
		flex-grow: 1;

		display: grid;
		grid-template-rows: auto auto auto;
		grid-column-gap: $page_padding-horizontal * 3;

		justify-items: flex-start;
		align-items: center;
		justify-content: flex-start;
		align-content: space-around;
		grid-auto-flow: column;
	}

	// Right column
	.drpam-logo {
		text-align: right;
		img {
			height: var(--logo-size);
			width: auto;
			margin-left: auto;
			display: block;
			margin-bottom: 1rem;
		}
		p {
			margin: 0;
			line-height: 1.65em;
		}
	}
	@include responsive(72rem) {
		padding: $page_padding-vertical $page_padding-horizontal;
		gap: $page_padding-horizontal * 2.5;
		--logo-size: 45px;

		.ndc-logo {
			p {
				font-size: 1.25rem;
				margin-top: 0.75em;
			}
		}

		.links {
			grid-column-gap: $page_padding-horizontal * 1.5;
			align-content: center;
			row-gap: $page_padding-vertical * 0.4;
		}
	}
	@include responsive(89rem) {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: auto auto;
		grid-template-areas:
			'logo links'
			'dr-pam links';
		grid-row-gap: $page_padding-vertical;

		.ndc-logo {
			grid-area: logo;
		}
		.links {
			grid-area: links;

			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;

			a {
				text-align: right;
				&:after {
					right: 0;
					left: unset;
				}
			}
		}
		.drpam-logo {
			grid-area: dr-pam;
			text-align: left;
			img {
				margin-left: 0;
				margin-right: auto;
			}
		}
	}
	@include responsive(30rem) {
		display: block;

		.ndc-logo {
			text-align: center;
			row-gap: 1rem;
			align-items: center;
			p {
				margin-top: 0;
			}
		}
		.links {
			align-items: center;
			margin: $page_padding-vertical * 1.5 0;
			row-gap: $page_padding-vertical * 0.65;
		}
		.drpam-logo {
			text-align: center;
			img {
				margin: 0 auto 1rem;
			}
		}
	}
}
