@import '@dr-pam/common-components/Styles/Themes/ndc';

body,
html {
	padding: 0;
	margin: 0;
	height: 100%;
	width: 100%;
	font-size: 15px;
	font-family: $font-body-normal-family;
}
html {
	scroll-behavior: smooth;
  }
  
a {
	text-decoration: none;
}

.switch-link-underline-direction {
	&:after,
	a:after {
		right: 0;
		left: unset;
	}
}

* {
	box-sizing: border-box;
}

// ------------------------------------------------------------------
// Variables
// ------------------------------------------------------------------

$page_padding-horizontal: $spacing-xxl;
$page_padding-vertical: calc($spacing-xxl * 1.5);

// Mobile responsiveness
$page-break_tablet: 38rem;
$page-break_desktop: 65rem;

* {
	--approx-menu-height: 6rem;
	// $page_padding-vertical + 3rem
}

$racgp-color: #2b3f54;

// ------------------------------------------------------------------
// Backgrounds
// ------------------------------------------------------------------

.dark-bg {
	background-color: $color-chinese-violet;
	color: $color-snow;
	a {
		color: inherit;
		&:after {
			border-color: $color-snow;
		}
	}
	.emphasised-text {
		color: $color-thistle;
	}
}

.light-bg {
	background-color: $color-white;
	color: $color-chinese-violet;
	a {
		color: inherit;
	}

	.emphasised-text {
		color: $color-rose-quartz;
	}
}

.icon-bg {
	background-image: url(/images/icon_bg.png);
	background-repeat: no-repeat;
	background-size: calc(100vw - 350px);
	background-position: -40px -40px;
}

.icon-bg_light-bg {
	background-image: url(/images/icon_bg_coloured.png);
	background-repeat: no-repeat;
	background-position: calc(100% + 40px) -40px;
	background-size: 600px;
	@include responsive(80rem) {
		background-image: url(/images/icon_bg_coloured-light.png);
	}
}

// ------------------------------------------------------------------
// Icons
// ------------------------------------------------------------------

svg.tabler-icon {
	vertical-align: bottom;
}

// ------------------------------------------------------------------
// Imports
// ------------------------------------------------------------------

@import 'footer.scss';
@import 'header.scss';
@import 'general-layout.scss';
@import 'homepage.scss';
@import 'page-specific-styles.scss';
