$section-width: calc(100% - 600px);
$restricted-width-resources: calc(100% - 500px);

.content-body {
	color: $color-primary;

	section {
		padding: $page_padding-vertical * 2 $page_padding-horizontal * 3;
		// Used in a variable in homepage.scss
		@include responsive(45rem) {
			padding: $page_padding-vertical * 1.5 $page_padding-horizontal * 1.5;
		}

		@include responsive(25rem) {
			padding: $page_padding-vertical $page_padding-horizontal;
		}

		> *:first-child,
		.ArticleHtml {
			& > *:first-child {
				margin-top: 0;
			}
			h1 {
				margin-bottom: 1rem;
			}
		}
	}

	p,
	li {
		line-height: 1.8rem;
		font-weight: 500;
	}
	p {
		margin: 0 0 2em;
		.emphasised-text {
			color: $color-rose-quartz;
		}
	}

	.no-list-style {
		list-style: none;
	}

	.cpd-provider {
		margin-top: $page_padding-vertical;
		p {
			color: $racgp-color;
			font-size: 0.75em;
			font-style: italic;
			font-weight: 600;
		}

		img {
			max-width: 250px;
			height: auto;
		}
	}

	h1,
	h2,
	h3,
	h4 {
		font-weight: 600;
		letter-spacing: $font-body-bold-spacing;
		margin: 2em 0 1em;
	}

	h2 {
		border-top: 2px solid $color-primary;
		padding-top: 1.5em;
	}
}

.restricted-width {
	max-width: $section-width;
	@include responsive(80rem) {
		max-width: none;
	}
}
.restricted-width-resources {
	max-width: $restricted-width-resources;
}

a {
	position: relative;
	word-break: normal;

	&::after {
		content: '';
		display: block;
		position: absolute;
		width: 0;
		left: 0;
		bottom: -0.5rem;
		border-bottom: 1.5px solid $color-primary;
		@include transition(width);
	}

	@include active-state {
		&::after {
			width: 100%;
		}
	}

	&.button {
		&::after {
			content: unset;
		}
	}
}
